@font-face {
  font-family: 'Ubuntu Condensed';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('ubuntu-condensed.woff2') format('woff2'),
       url('ubuntu-condensed.woff') format('woff');
}
body {
        background: #191919;
        color: #fff;
        font-family: 'Ubuntu Condensed';
}
